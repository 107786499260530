import * as idProtector from '../../server/services/idProtector';
import { z } from 'zod';

// Helper function for custom ID validation
const isValidId = (value: string) => idProtector.isValid(value);

/**
 * This schema is used for validation of data that is sent to the server when user is creating a new Datatron device.
 * It checks if all required fields are present and if their values are valid.
 * It also checks if locationId, organizationId and deviceTypeId are valid IDs.
 */
const CreateDatatronDeviceSchema = z.object({
  name: z.string(),
  deviceUUID: z.string().uuid(),
  serialNumber: z.string(),
  locationId: z.string().refine(isValidId, {
    message: 'errors.field.invalid',
  }),
  organizationId: z.string().refine(isValidId, {
    message: 'errors.field.invalid',
  }),
  deviceTypeId: z.string().refine(isValidId, {
    message: 'errors.field.invalid',
  }),
  networkName: z.string().optional(),
});

const CreateDeviceParamSchema = z.object({
  datatronId: z.number(),
  datatronTs: z.date(),
  accountId: z.number(),
});

const UpdateDeviceParamSchema = z.object({
  deviceId: z.number(),
  deviceTs: z.date(),
  datatronId: z.number(),
  datatronTs: z.date(),
  accountId: z.number(),
});

/**
 * This schema is used for validation of data that is sent to the server when user is updating an existing Datatron device.
 * It checks if all required fields are present and if their values are valid.
 * Optional fields are:
 * - name
 * - serialNumber
 * - networkName
 */
const UpdateDatatronDeviceSchema = z.object({
  name: z.string().min(1, 'errors.field.invalid').optional(),
  serialNumber: z.string().min(1, 'errors.field.invalid').optional(),
  networkName: z.string().optional(),
});

// Types
type CreateDatatronDevice = z.infer<typeof CreateDatatronDeviceSchema>;
type UpdateDatatronDevice = z.infer<typeof UpdateDatatronDeviceSchema>;

type DatatronDeviceError = Partial<Record<keyof CreateDatatronDevice, string>>;

export {
  UpdateDeviceParamSchema,
  CreateDeviceParamSchema,
  CreateDatatronDeviceSchema,
  UpdateDatatronDeviceSchema,
  CreateDatatronDevice,
  UpdateDatatronDevice,
  DatatronDeviceError,
};
