import { defineMessages } from 'react-intl';

export default defineMessages({
  address: {
    id: 'dataPoint.config.address',
    defaultMessage: 'Address',
  },
  alias: {
    id: 'dataPoint.config.alias',
    defaultMessage: 'Alias',
  },
  amsNetworkId: {
    id: 'device.config.amsNetworkId',
    defaultMessage: 'AMS Network ID',
  },
  area: {
    id: 'device.config.area',
    defaultMessage: 'Area',
  },
  arrayDimensions: {
    id: 'dataPoint.config.arrayDimensions',
    defaultMessage: 'Array Dimensions',
  },
  arrayIndex: {
    id: 'dataPoint.config.arrayIndex',
    defaultMessage: 'Array Index',
  },
  bitOffset: {
    id: 'dataPoint.config.bitOffset',
    defaultMessage: 'Bit Offset',
  },
  block: {
    id: 'device.config.block',
    defaultMessage: 'Block',
  },
  browseName: {
    id: 'dataPoint.config.browseName',
    defaultMessage: 'Browse Name',
  },
  byteCount: {
    id: 'dataPoint.config.byteCount',
    defaultMessage: 'Byte Count',
  },
  colorMode: {
    id: 'dataPoint.config.colorMode',
    defaultMessage: 'Color Mode',
  },
  column: {
    id: 'device.config.column',
    defaultMessage: 'Column',
  },
  comment: {
    id: 'dataPoint.config.comment',
    defaultMessage: 'Comment',
  },
  connectorImageRegistry: {
    id: 'device.config.connectorImageRegistry',
    defaultMessage: 'Connector Image Registry',
  },
  connectorImageRepository: {
    id: 'device.config.connectorImageRepository',
    defaultMessage: 'Connector Image Repository',
  },
  connectorImageTag: {
    id: 'device.config.connectorImageTag',
    defaultMessage: 'Connector Image Tag',
  },
  coupling: {
    id: 'dataPoint.config.coupling',
    defaultMessage: 'Coupling',
  },
  custom: {
    id: 'device.config.custom',
    defaultMessage: 'Custom Config',
  },
  cycleMinMsec: {
    id: 'device.config.cycleMinMsec',
    defaultMessage: 'Min. Cycle Scan Time (in ms)',
  },
  soapEndpoint: {
    id: 'device.config.custom.soapEndpoint',
    defaultMessage: ' SOAP Endpoint',
  },
  soapUser: {
    id: 'device.config.custom.soapUser',
    defaultMessage: 'SOAP User',
  },
  soapPassword: {
    id: 'device.config.custom.soapPassword',
    defaultMessage: 'SOAP Password',
  },
  fetchDelayMsec: {
    id: 'device.config.custom.fetchDelayMsec',
    defaultMessage: 'Fetch Delay (in ms)',
  },
  fetchConcurrency: {
    id: 'device.config.custom.fetchConcurrency',
    defaultMessage: 'Fetch Concurrency',
  },
  multiConDeviceUrl: {
    id: 'device.config.custom.multiConDeviceUrl',
    defaultMessage: 'Multi-con Device URL',
  },
  multiConDeviceInfoRefreshIntervalSec: {
    id: 'device.config.custom.multiConDeviceInfoRefreshIntervalSec',
    defaultMessage: 'Multi-con Device Info Refresh Interval (in sec)',
  },
  internal: {
    id: 'dataPoint.config.internal',
    defaultMessage: 'Config',
  },
  dataBlockNumber: {
    id: 'dataPoint.config.dataBlockNumber',
    defaultMessage: 'Data Block Number',
  },
  dataClass: {
    id: 'dataPoint.config.dataClass',
    defaultMessage: 'Data Class',
  },
  dataModelReady: {
    id: 'dataPoint.config.dataModelReady',
    defaultMessage: 'Data Model Ready',
  },
  dataType: {
    id: 'dataPoint.config.dataType',
    defaultMessage: 'Data Type',
  },
  destination: {
    id: 'dataPoint.config.destination',
    defaultMessage: 'Destination',
  },
  dimensionX: {
    id: 'dataPoint.config.dimensionX',
    defaultMessage: 'Dimension X',
  },
  dimensionY: {
    id: 'dataPoint.config.dimensionY',
    defaultMessage: 'Dimension Y',
  },
  displayName: {
    id: 'dataPoint.config.displayName',
    defaultMessage: 'Display Name',
  },
  dpUnit: {
    id: 'device.config.dpUnit',
    defaultMessage: 'Unit (NCK)',
  },
  enabled: {
    id: 'dataPoint.config.enabled',
    defaultMessage: 'Enabled',
  },
  endMemoryOffset: {
    id: 'device.config.endMemoryOffset',
    defaultMessage: 'End Memory Offset',
  },
  envStr: {
    id: 'device.config.envStr',
    defaultMessage: 'Env',
  },
  firstIDS: {
    id: 'device.config.firstIDS',
    defaultMessage: 'First IDS',
  },
  flowId: {
    id: 'dataPoint.config.flowId',
    defaultMessage: 'Flow ID',
  },
  flowUrl: {
    id: 'dataPoint.config.flowUrl',
    defaultMessage: 'Flow URL',
  },
  fps: {
    id: 'dataPoint.config.fps',
    defaultMessage: 'FPS',
  },
  frameSize: {
    id: 'device.config.frameSize',
    defaultMessage: 'Frame Size',
  },
  identifier: {
    id: 'dataPoint.config.identifier',
    defaultMessage: 'Identifier',
  },
  ignoreInvalidSymbols: {
    id: 'device.config.ignoreInvalidSymbols',
    defaultMessage: 'Ignore Invalid Symbols',
  },
  plcIpAddress: {
    id: 'device.config.plcIpAddress',
    defaultMessage: 'PLC IP Address',
  },
  plcPath: {
    id: 'device.config.plcPath',
    defaultMessage: 'PLC Path',
  },
  opcuaUser: {
    id: 'device.config.opcuaUser',
    defaultMessage: 'OPC UA User',
  },
  opcuaTimeout: {
    id: 'device.config.opcuaTimeout',
    defaultMessage: 'OPC UA Timeout',
  },
  opcuaCertFile: {
    id: 'device.config.opcuaCertFile',
    defaultMessage: 'OPC UA Certificate File',
  },
  opcuaEndpoint: {
    id: 'device.config.opcuaEndpoint',
    defaultMessage: 'OPC UA Endpoint',
  },
  opcuaPassword: {
    id: 'device.config.opcuaPassword',
    defaultMessage: 'OPC UA Password',
  },
  opcuaFetchMode: {
    id: 'device.config.opcuaFetchMode',
    defaultMessage: 'OPC UA Fetch Mode',
  },
  opcuaRootNodeId: {
    id: 'device.config.opcuaRootNodeId',
    defaultMessage: 'OPC UA Root Node ID',
  },
  fastReadRateMsec: {
    id: 'device.config.fastReadRateMsec',
    defaultMessage: 'Fast Read Rate (in ms)',
  },
  opcuaSubMaxItems: {
    id: 'device.config.opcuaSubMaxItems',
    defaultMessage: 'OPC UA Subscription Max Items',
  },
  slowReadRateMsec: {
    id: 'device.config.slowReadRateMsec',
    defaultMessage: 'Slow Read Rate (in ms)',
  },
  opcuaSecurityMode: {
    id: 'device.config.opcuaSecurityMode',
    defaultMessage: 'OPC UA Security Mode',
  },
  opcuaSubQueueSize: {
    id: 'device.config.opcuaSubQueueSize',
    defaultMessage: 'OPC UA Subscription Queue Size',
  },
  opcuaPrivateKeyFile: {
    id: 'device.config.opcuaPrivateKeyFile',
    defaultMessage: 'OPC UA Private Key File',
  },
  opcuaSecurityPolicy: {
    id: 'device.config.opcuaSecurityPolicy',
    defaultMessage: 'OPC UA Security Policy',
  },
  plcType: {
    id: 'device.config.plcType',
    defaultMessage: 'PLC Type',
  },
  path: {
    id: 'device.config.path',
    defaultMessage: 'Path',
  },
  cardNumber: {
    id: 'device.config.cardNumber',
    defaultMessage: 'Card Number',
  },
  loopDelayMs: {
    id: 'device.config.loopDelayMs',
    defaultMessage: 'Loop Delay (in ms)',
  },
  connType: {
    id: 'device.config.connType',
    defaultMessage: 'Connection Type',
  },
  plcNumber: {
    id: 'device.config.plcNumber',
    defaultMessage: 'PLC Number',
  },
  number: {
    id: 'device.config.number',
    defaultMessage: 'Number',
  },
  deviceNumber: {
    id: 'device.config.deviceNumber',
    defaultMessage: 'Device Number',
  },
  projectFilePath: {
    id: 'device.config.projectFilePath',
    defaultMessage: 'Project File Path',
  },
  password: {
    id: 'device.config.password',
    defaultMessage: 'Password',
  },
  secure: {
    id: 'device.config.secure',
    defaultMessage: 'Secure',
  },
  timeout: {
    id: 'device.config.timeout',
    defaultMessage: 'Timeout',
  },
  baudRate: {
    id: 'device.config.baudRate',
    defaultMessage: 'Baud Rate',
  },
  serialPort: {
    id: 'device.config.serialPort',
    defaultMessage: 'Serial Port',
  },
  ecMasterCpuCore: {
    id: 'device.config.ecMasterCpuCore',
    defaultMessage: 'EC Master CPU Core',
  },
  ecMasterDeviceMac: {
    id: 'device.config.ecMasterDeviceMac',
    defaultMessage: 'EC Master Device MAC',
  },
  ecMasterDeviceModule: {
    id: 'device.config.ecMasterDeviceModule',
    defaultMessage: 'EC Master Device Module',
  },
  listIDS: {
    id: 'device.config.listIDS',
    defaultMessage: 'List IDS',
  },
  idsType: {
    id: 'device.config.idsType',
    defaultMessage: 'IDS Type',
  },
  indexGroup: {
    id: 'config.indexGroup',
    defaultMessage: 'Index Group',
  },
  indexGroupOffset: {
    id: 'dataPoint.config.indexGroupOffset',
    defaultMessage: 'Index Group Offset',
  },
  indexOffset: {
    id: 'dataPoint.config.indexOffset',
    defaultMessage: 'Index Offset',
  },
  ipAddressLocal: {
    id: 'device.config.ipAddressLocal',
    defaultMessage: 'IP Address Local',
  },
  ipAddressRemote: {
    id: 'device.config.ipAddressRemote',
    defaultMessage: 'IP Address Remote',
  },
  isMaxRate: {
    id: 'dataPoint.config.isMaxRate',
    defaultMessage: 'Use Max Rate',
  },
  label: {
    id: 'dataPoint.config.label',
    defaultMessage: 'Label',
  },
  lastIDS: {
    id: 'device.config.lastIDS',
    defaultMessage: 'Last IDS',
  },
  localAmsId: {
    id: 'device.config.localAmsId',
    defaultMessage: 'Local AMS ID',
  },
  localNet: {
    id: 'device.config.localNet',
    defaultMessage: 'Local Net',
  },
  localNode: {
    id: 'device.config.localNode',
    defaultMessage: 'Local Node',
  },
  localUnit: {
    id: 'device.config.localUnit',
    defaultMessage: 'Local Unit',
  },
  maxCountSumRequest: {
    id: 'device.config.maxCountSumRequest',
    defaultMessage: 'Max Count Sum Request',
  },
  name: {
    id: 'dataPoint.config.name',
    defaultMessage: 'Name',
  },
  ncuCycleTime: {
    id: 'device.config.ncuCycleTime',
    defaultMessage: 'NCU Cycle Time',
  },
  nodePath: {
    id: 'dataPoint.config.nodePath',
    defaultMessage: 'Node Path',
  },
  numberOfChannels: {
    id: 'dataPoint.config.numberOfChannels',
    defaultMessage: 'Number of Channels',
  },
  offset: {
    id: 'dataPoint.config.offset',
    defaultMessage: 'Offset',
  },
  originAdditionalSource: {
    id: 'dataPoint.config.originAdditionalSource',
    defaultMessage: 'Origin Additional Source',
  },
  originClass: {
    id: 'dataPoint.config.originClass',
    defaultMessage: 'Origin Class',
  },
  payloadSize: {
    id: 'device.config.payloadSize',
    defaultMessage: 'Payload Size',
  },
  pdoEntry: {
    id: 'dataPoint.config.pdoEntry',
    defaultMessage: 'PDO Entry',
  },
  plcCycleTime: {
    id: 'device.config.plcCycleTime',
    defaultMessage: 'PLC Cycle Time',
  },
  portNumber: {
    id: 'device.config.portNumber',
    defaultMessage: 'Port Number',
  },
  position: {
    id: 'dataPoint.config.position',
    defaultMessage: 'Position',
  },
  productCode: {
    id: 'dataPoint.config.productCode',
    defaultMessage: 'Product Code',
  },
  profinetName: {
    id: 'device.config.profinetName',
    defaultMessage: 'Network Name',
  },
  protocol: {
    id: 'device.config.protocol',
    defaultMessage: 'Protocol',
  },
  rackNumber: {
    id: 'device.config.rackNumber',
    defaultMessage: 'Rack Number',
  },
  range: {
    id: 'dataPoint.config.range',
    defaultMessage: 'Range',
  },
  rangeMax: {
    id: 'dataPoint.config.rangeMax',
    defaultMessage: 'Range Max',
  },
  rangeMin: {
    id: 'dataPoint.config.rangeMin',
    defaultMessage: 'Range Min',
  },
  rate: {
    id: 'dataPoint.config.rate',
    defaultMessage: 'Rate',
  },
  accessMethod: {
    id: 'dataPoint.config.accessMethod',
    defaultMessage: 'Access Method',
  },
  toolIds: {
    id: 'device.config.toolIds',
    defaultMessage: 'Tool IDs',
  },
  mappingType: {
    id: 'dataPoint.config.mappingType',
    defaultMessage: 'Mapping Type',
  },
  remoteNet: {
    id: 'device.config.remoteNet',
    defaultMessage: 'Remote Net',
  },
  remoteNode: {
    id: 'device.config.remoteNode',
    defaultMessage: 'Remote Node',
  },
  remoteUnit: {
    id: 'device.config.remoteUnit',
    defaultMessage: 'Remote Unit',
  },
  resolutionPerChannel: {
    id: 'dataPoint.config.resolutionPerChannel',
    defaultMessage: 'Resolution per Channel',
  },
  row: {
    id: 'device.config.row',
    defaultMessage: 'Row',
  },
  rowCount: {
    id: 'device.config.rowCount',
    defaultMessage: 'Row Count',
  },
  samplingRate: {
    id: 'dataPoint.config.samplingRate',
    defaultMessage: 'Sampling Rate',
  },
  scalingFactor: {
    id: 'dataPoint.config.scalingFactor',
    defaultMessage: 'Scaling Factor',
  },
  scalingOffset: {
    id: 'dataPoint.config.scalingOffset',
    defaultMessage: 'Scaling Offset',
  },
  size: {
    id: 'dataPoint.config.size',
    defaultMessage: 'Size',
  },
  slotNumber: {
    id: 'device.config.slotNumber',
    defaultMessage: 'Slot Number',
  },
  source: {
    id: 'dataPoint.config.source',
    defaultMessage: 'Source',
  },
  startMemoryOffset: {
    id: 'device.config.startMemoryOffset',
    defaultMessage: 'Start Memory Offset',
  },
  stringEncoding: {
    id: 'device.config.stringEncoding',
    defaultMessage: 'String Encoding (HEHE)',
  },
  stringLength: {
    id: 'device.config.stringLength',
    defaultMessage: 'String Length (ROBIN LOL)',
  },
  symbolName: {
    id: 'dataPoint.config.symbolName',
    defaultMessage: 'Symbol Name',
  },
  transformationSource: {
    id: 'dataPoint.config.transformationSource',
    defaultMessage: 'Transformation Source',
  },
  type: {
    id: 'dataPoint.config.type',
    defaultMessage: 'Type',
  },
  unit: {
    id: 'dataPoint.config.unit',
    defaultMessage: 'Unit',
  },
  uuid: {
    id: 'dataPoint.config.uuid',
    defaultMessage: 'UUID',
  },
  value: {
    id: 'dataPoint.config.value',
    defaultMessage: 'Value',
  },
  vendorId: {
    id: 'dataPoint.config.vendorId',
    defaultMessage: 'Vendor ID',
  },
  varName: {
    id: 'dataPoint.config.custom.varName',
    defaultMessage: 'Variable Name',
  },
  deviceFmId: {
    id: 'dataPoint.config.custom.deviceFmId',
    defaultMessage: 'Device FmId',
  },
  deviceLabel: {
    id: 'dataPoint.config.custom.deviceLabel',
    defaultMessage: 'Device Label',
  },
  useCommonTimestamp: {
    id: 'dataPoint.config.custom.useCommonTimestamp',
    defaultMessage: 'Use common timestamp',
  },
  channelNum: {
    id: 'dataPoint.config.custom.channelNum',
    defaultMessage: 'Channel Number',
  },
});
