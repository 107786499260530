import _ from 'lodash';
import config from '../config/public';
import { z } from 'zod';
import { REQUIRED, INVALID, INVALID_UUID, INVALID_IP } from './constants/errors';

export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const sequelizeToPlain = (object) => {
  if (object && object.get && typeof object.get === 'function') {
    return _.cloneDeep(object.get({ plain: true }));
  }
  return _.cloneDeep(object);
};

export const diffInSec = (from, to) => (to.getTime() - from.getTime()) / 1000;

export const isPreviewEnabled = () => config.features.preview;

export const bytesToMega = (bytes) => {
  const megabytes = (bytes / (1024 * 1024)).toFixed(2);
  return megabytes;
};

/**
 * Transforms a Zod error object into a Record<string, string> error object where
 * the keys are the paths of the invalid fields and the values are the error messages.
 *
 * @param zodError - The zod error to transform.
 * @returns A Record<string, string> error object.
 */
export function transformZodError(zodError: z.ZodError): Record<string, string> {
  const transformedError: Record<string, string> = {};

  for (const issue of zodError.issues) {
    const path = issue.path.join('.');
    let errorMessage = '';

    switch (issue.message) {
      case 'Required':
        errorMessage = REQUIRED;
        break;
      case 'Invalid':
        errorMessage = INVALID;
        break;
      case 'Expected number, received null':
        errorMessage = INVALID;
        break;
      case 'Invalid ip':
        errorMessage = INVALID_IP;
        break;
      case 'Invalid uuid':
        errorMessage = INVALID_UUID;
        break;
      case "Invalid discriminator value. Expected 'ids_range_input' | 'ids_list_input'":
        errorMessage = REQUIRED;
        break;
      default:
        errorMessage = issue.message;
    }

    transformedError[path] = errorMessage;
  }

  return transformedError;
}
